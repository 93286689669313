import { namespaceConfig, dynamicPropertyConfig } from 'fast-redux';
import { fetcher } from '../utils/fetcher';

const DEFAULT_STATE = {
  items: [],
  isFetching: false,
  didInvalidate: false,
  lastUpdated: null
};
const { action } = namespaceConfig('products', DEFAULT_STATE);

const {
  propertyAction: productsAction,
  getPropertyState: getProductsState
} = dynamicPropertyConfig(action, DEFAULT_STATE);

export { getProductsState };

export const requestProducts = productsAction('requestProducts', (state) => ({
  ...state,
  items: [],
  isFetching: true,
  didInvalidate: false
}));

export const invalidateProducts = productsAction(
  'invalidateProducts',
  (state) => ({
    ...state,
    didInvalidate: true
  })
);
export const receiveProducts = action('receiveProducts', (state, data) => ({
  ...state,
  isFetching: false,
  didInvalidate: false,
  items: data,
  lastUpdated: Date.now()
}));

export const fetchProducts = () => (dispatch) => {
  dispatch(requestProducts());
  fetcher('/api/products').then(({ data, error }) => {
    if (error) return false;
    return dispatch(receiveProducts(data));
  });
};

const shouldFetchProducts = (products) => {
  if (!products.items || products.items.length === 0) {
    return true;
  }
  if (products.isFetching) {
    return false;
  }
  return products.didInvalidate;
};

export const fetchProductsIfNeed = (products) => (dispatch, getState) => {
  const state = getProductsState(getState(), products);
  if (shouldFetchProducts(state)) {
    return dispatch(fetchProducts());
  }
};
