import { namespaceConfig, dynamicPropertyConfig } from 'fast-redux';

const DEFAULT_STATE = {};
const { action } = namespaceConfig('company', DEFAULT_STATE);

const { getPropertyState: getCompanyState } = dynamicPropertyConfig(
  action,
  DEFAULT_STATE
);

export { getCompanyState };
