import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import Link from 'next/link';
import { getCompanyState } from '../stores/company';
import { fetchProductsIfNeed } from '../stores/products';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { CompanyInterface } from '../models/company';
import type { ProductInterface } from '../models/product';
import ReactTooltip from 'react-tooltip';
import styles from './home.module.scss';

type HomeProps = {
  company?: CompanyInterface;
  products: Array<ProductInterface>;
  dispatch: any;
};
const Home = (props: HomeProps) => {
  const { company, products, dispatch } = props;

  useEffect(() => {
    if (company && company._id) {
      dispatch(fetchProductsIfNeed(products));
    }
  }, []);

  if (!company && !company._id) {
    return <div />;
  }

  return (
    <Container fluid className={`d-flex ${styles.contentContainer}`}>
      <Row>
        <Col lg={5} className="text-center">
          <Image
            className={styles.heroImage}
            src={company.customization.homepageImage}
          />
        </Col>
        <Col lg={7} className={`${styles.mainContent} align-self-center`}>
          <h1>
            Personalized cans with your favorite {company.shortName} brew!
          </h1>
          <p>
            Impress your clients, friends, or employees with custom cans filled
            with your favorite&nbsp;{company.shortName} brew! This is just an
            order request form, but you&apos;ll know quickly if the{' '}
            {company.shortName} team can make your custom can dreams come true!
            <br />
            <br />
          </p>
          <div>
            <strong>WHAT YOU NEED</strong>
            <br />

            <ul className="list-flushLeft">
              <li key="options">
                Know your{' '}
                <a className="tip-link" data-tip="brews">
                  {company.shortName} brew options.
                </a>
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  backgroundColor="#000000"
                >
                  <div className={styles.tooltipLabel}>
                    {company.legalName} Options
                  </div>
                  <div className={styles.brewOptions}>
                    {products.map((product) => (
                      <div key={product._id} className={styles.brewPhoto}>
                        <img src={product.photo} />
                      </div>
                    ))}
                  </div>
                </ReactTooltip>
              </li>
              <li key="photo">Have your high-resolution photo ready!</li>
              <li key="cans">
                Know when you need your cans in hand.<sup>**</sup>
              </li>
            </ul>
          </div>
          <div className={styles.finePrint}>
            **Plan a minimum of <strong>{company.leadTime} days</strong> for
            your awesome custom cans. Timing is based on printing and dates.
          </div>
          <Link href="/order" passHref>
            <Button className={styles.requestBtn}>Start Order Request</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(state) {
  const { products } = state;
  const items = products ? products.items : [];
  return {
    ...getCompanyState(state),
    products: items
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
