import { GetServerSideProps } from 'next';
import { Fragment } from 'react';
import Navigation from '../components/nav';
import Home from '../containers/home';
import RequestError from '../components/modals/requestError';
import RequestSuccess from '../components/modals/requestSuccess';
import { reduxPage } from '../stores/redux';
import CanimalDefault from '../components/canimal-default';

export const HomePage = (props) => {
  if (!props.company || !props.company._id) {
    return <CanimalDefault />;
  }

  return (
    <Fragment>
      <RequestError {...props} />
      <RequestSuccess {...props} />
      <Navigation {...props} pageTitle={'Order Request Form'} />
      <Home {...props} />
    </Fragment>
  );
};

export default reduxPage(HomePage);

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { res } = ctx;
  // await applySubdomain(req, res);
  try {
    const company = res['company'] !== 'null' ? JSON.parse(res['company']) : {};
    return {
      props: {
        company
      }
    };
  } catch (err) {
    return { props: {} };
  }
};
