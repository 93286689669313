import { Container, Row, Col } from 'react-bootstrap';
import styles from './canimal-default.module.scss';
const CanimalDefault = () => {
  return (
    <Container fluid>
      <Row>
        <Col lg={6} className={styles.mainContent}>
          <img src="mycustomcans.png" alt="My Custom Cans" />
        </Col>
      </Row>
    </Container>
  );
};

export default CanimalDefault;
